import { ENDPIONTS, httpService, PaginatedResult } from '@api';
import { TotalsViewModel } from '@viewModels';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';

const InvoicesInTotals = () => {

  const [queryStrings] = useSearchParams();

  const [isFetchingPage, setIsFetchingPage] = useState(false);
  const [data, setData] = useState<TotalsViewModel[]>([]);

  useEffect(() => {
    var url = window.location.href;
    if (url.indexOf('?') !== -1) { // url has query string
      (async function () {
        setIsFetchingPage(true);

        var params = {
          from: queryStrings.get("createdAt.gte"),
          to: queryStrings.get("createdAt.lte"),
          tin: queryStrings.get("tin.eq"),
          tradeName: queryStrings.get("tradeName.like"),
          toUsd: queryStrings.get("toUsd") ? queryStrings.get("toUsd") as unknown as boolean : false,
          toSlsh: queryStrings.get("toSlsh") ? queryStrings.get("toSlsh") as unknown as boolean : false,

        }

        var res = await httpService(ENDPIONTS.Totals, queryStrings).post(params);
        setData(res.data.items);
        // setTotalFilteredInvoices((res.data as PaginatedResult<TotalsViewModel>).totalItems);
        setIsFetchingPage(false);
      })();
    } else {
      setData([]);
    }

  }, [queryStrings]);

  return (
    <>
      {
        data.map((value: any) => {
          return (
            <div className='d-flex col-12 w-100' style={{ marginTop: 40 }}>

              <div className='col-2'>
                <p style={{ fontSize: "1.2rem" }}>
                  {value?.currency ?? "SLSH"}
                </p>
                <div className='d-flex flex-column px-1 alert-warning mb-1 px-4 pt-1' style={{ border: "1px solid #e6e6e6" }}>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }}>Recipets</small>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }} className='ml-auto'>{value?.totalInvoices ?? 0}</small>
                </div>
                <div className='d-flex flex-column px-1 alert-secondary px-4 pt-1' style={{ border: "1px solid #e6e6e6" }}>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }}>Normal Sale Receipt</small>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }} className='ml-auto'>0</small>
                </div>
              </div>
              <div className='col-2'>
                <p style={{ fontSize: "1.2rem" }}>
                  Total
                </p>
                <div className='d-flex flex-column px-1 alert-warning mb-1 px-4 pt-1' style={{ border: "1px solid #e6e6e6" }}>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }}>Total</small>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }} className='ml-auto'>{(value?.total as number).toCurrency(value?.currency === "SLSH" ? "SLSH" : "$") ?? 0}</small>
                </div>
              </div>
              <div className='col-2'>
                <p style={{ fontSize: "1.2rem" }}>
                  Taxable
                </p>
                <div className='d-flex flex-column px-1 alert-warning mb-1 px-4 pt-1' style={{ border: "1px solid #e6e6e6" }}>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }}>Total</small>
                  <small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }} className='ml-auto'>{(value?.totalSales as number).toCurrency(value?.currency === "SLSH" ? "SLSH" : "$") ?? 0}</small>
                </div>
              </div >
              <div className='col-2'>
                <p style={{ fontSize: "1.2rem" }}>
                  GST
                </p>
                <div className='d-flex flex-column px-1 alert-warning mb-1 px-4 pt-1' style={{ border: "1px solid #e6e6e6" }}>
                  <small style={{
                    color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem"
                  }}>Total</small>
                  < small style={{ color: "#d2691e", fontWeight: "bold", fontSize: "0.8rem" }} className='ml-auto'>{(value?.totalTax as number).toCurrency(value?.currency === "SLSH" ? "SLSH" : "$") ?? 0}</small>
                </div>
              </div >

            </div >
          )
        })
      }
    </>

  )
}

export default InvoicesInTotals