export enum ENDPIONTS {
  Invoices = "invoices",
  CountInvoices = "invoices/count",
  OrderDetails = "invoices/orderDetails",
  GroupedInvoices = "invoices/groupedOrders",
  Totals = "invoices/totals",
  taxpayers = "taxpayer",
  taxpayersActivities = "taxpayer/activities",
  login = "login",
  user = "user",
  recovery = "user/sendEmail",
  userChnagePassword = "user/changePassword",
  roles = "roles",
  exchange = "exchange",
  OTP = "login/validateOTP",
  ReSendSMS = "login/sendSMS",
}
