import { ENDPIONTS, httpService, PaginatedResult } from '@api';
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Exchange } from '@models';
import { Action, ComplexHeader, Table } from '@shared';
import { FiEdit } from 'react-icons/fi';
import EditExchange from './EditExchange';

const Exchanges = () => {
    const [queryStrings] = useSearchParams();

    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const { register, handleSubmit, errors } = useForm();

    const [data, setData] = useState<PaginatedResult<Exchange>>(new PaginatedResult());
    const [isFetchingPage, setIsFetchingPage] = useState(false);

    const fetchDate = useCallback(async () => {
        var res = await httpService(ENDPIONTS.exchange, queryStrings).getAll()
        console.log("res", res);
        setData(res.data);
        setIsFetchingPage(false);
    }, [queryStrings]);

    useEffect(() => {

        setIsFetchingPage(true);

        fetchDate();
    }, [fetchDate]);

    const headers: ComplexHeader[] = [

        { key: "name", title: "Exchange Name" },
        { key: "rate", title: "Exchange Rate" },
    ];

    const actions: Action[] = [
        {
            key: '1',
            click: (exchange: Exchange) => {
                showDocDetails(exchange);
            },
            title: "Edit",
            icon: FiEdit,
            color: "success"
        },
    ];

    const handlePageChange = (page: number, size: number) => {
        queryStrings.set('size', size.toString());
        queryStrings.set('page', (page - 1).toString());

        navigate({ search: `?${queryStrings.toString()}` });
    }


    const showDocDetails = (exchange: Exchange) => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: true,
            width: 600,
            html: <EditExchange exchange={exchange} callback={fetchDate} />
        })
    }


    return (
        <div className="col-8 offset-2 layout-top-spacing mt-5">
            <div className="skills layout-spacing ">
                <div className="widget-content widget-content-area">
                    <Table data={data} headers={headers} actions={actions} showCounter isFetchingPage={isFetchingPage} />
                </div>
            </div>
        </div>
    )
}

export default Exchanges