import React, { useState } from 'react'
import { MyContainer } from '@components'
import { useForm } from 'react-hook-form';
import { GetTaxpayerViewModel, TaxpayerViewModel } from '@viewModels';
import { Taxpayer } from '@models';
import { ENDPIONTS, httpService } from '@api';
import Swal from 'sweetalert2';

interface Props {
    taxpayer?: GetTaxpayerViewModel;
    callback?: () => void
}

export const AddTaxpayer: React.FC<Props> = ({ taxpayer, callback }) => {

    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);


    const onSubmit = async (data: any, e: any) => {
        setIsLoading(true);
        if (taxpayer) { // update
            const obj: TaxpayerViewModel = {
                tin: data?.tin,
                taxpayerName: data?.taxpayerName,
                section: data?.section,
                currency: data?.currency,
                deviceId: data?.deviceId,
                targetDB: data?.targetDB,
            }
            var res = await httpService(ENDPIONTS.taxpayers).update(taxpayer.id, obj);
            if (res?.status === 200) {
                //  taxpayer = new TaxpayerViewModel();
                Swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    text: "The record has been updated successfully.",
                });
                callback?.();
            }
        } else { // save
            const obj: TaxpayerViewModel = {
                tin: data?.tin,
                taxpayerName: data?.taxpayerName,
                section: data?.section,
                currency: data?.currency,
                deviceId: data?.deviceId,
                targetDB: data?.targetDB,
            }

            var result = await httpService(ENDPIONTS.taxpayers).post(obj);
            if (result?.status === 200) {
                // taxpayer = new TaxpayerViewModel();
                Swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    text: "The record has been updated Created.",
                });
                callback?.();
                e.target.reset();
            }
        }
        setIsLoading(false);
    }


    return (
        <MyContainer title={taxpayer ? 'Edit Taxpayers' : 'Add New Taxpayer'} head={taxpayer ? 'Edit Taxpayers' : 'Add New Taxpayer'}  >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-1" style={{ marginTop: 60 }}>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="tin">TIN Number*</label>
                            <input
                                defaultValue={taxpayer?.tin}
                                type="text"
                                className="form-control"
                                id="tin"
                                name="tin"
                                ref={register({ required: true })}
                            />
                            <span className="text-danger">
                                {errors.tin && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>

                </div>
                <div className="row mb-1">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="taxpayerName">Taxpayer Name*</label>
                            <input
                                defaultValue={taxpayer?.taxpayerName}
                                type="text"
                                className="form-control"
                                id="taxpayerName"
                                name="taxpayerName"
                                ref={register({ required: true })}
                            />
                            <span className="text-danger">
                                {errors.taxpayerName && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>

                </div>
                <div className="row mb-1">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="deviceId">Device ID*</label>
                            <input
                                defaultValue={taxpayer?.deviceId}
                                type="text"
                                className="form-control"
                                id="deviceId"
                                name="deviceId"
                                ref={register({ required: false })}
                            />
                            <span className="text-danger">
                                {errors.taxpayerName && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="section">Section*</label>
                        <select className="form-control"
                            defaultValue={taxpayer?.section}
                            id="section"
                            name="section"
                            ref={register({ required: true })}
                        >
                            <option ></option>
                            <option value="Restaurant">Restaurant</option>
                            <option value="Hotel">Hotel</option>
                            <option value="SuperMarket">SuperMarket</option>

                        </select>
                        <span className="text-danger">
                            {errors.section && <span>This field is required</span>}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="currency">Currency*</label>
                            <select className="form-control"
                                id="currency"
                                defaultValue={taxpayer?.currency}
                                name="currency"
                                ref={register({ required: true })}
                            >
                                <option ></option>
                                <option value="USD">USD</option>
                                <option value="SLSH">SLSH</option>

                            </select>
                            <span className="text-danger">
                                {errors.currency && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="section">Target DB*</label>
                            <select className="form-control"
                                defaultValue={taxpayer?.targetDB}
                                id="targetDB"
                                name="targetDB"
                                ref={register({ required: true })}
                            >
                                <option ></option>
                                <option value="ELTRADE">ELTRADE</option>
                                <option value="MoFTA">MoFTA</option>
                            </select>
                            <span className="text-danger">
                                {errors.targetDB && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col ">
                        <input type="submit" value={isLoading ? "loading...." : taxpayer ? "Edit Taxpayer" : "Register Taxpayer"} style={{ padding: 10 }} className=' float-right btn btn-primary' disabled={isLoading} />
                    </div>
                </div>
            </form>
        </MyContainer>
    )
}
