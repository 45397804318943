import { ENDPIONTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { Action, ComplexHeader, Table, TableDefaults } from "@shared";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FiActivity,
  FiCheck,
  FiDelete,
  FiEdit,
  FiFileText,
  FiX,
} from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Taxpayer } from "@models";
import { AddTaxpayer } from "./AddTaxpayer";
import { GetTaxpayerViewModel, TaxpayerViewModel } from "@viewModels";
import { useForm } from "react-hook-form";

interface Props {
  setTotalFilteredInvoices: React.Dispatch<React.SetStateAction<number>>;
}

const Taxpayers = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const { register, handleSubmit, errors } = useForm();
  const [totalFilteredInvoices, setTotalFilteredInvoices] = useState(0);

  // const [data, setData] = useState<PaginatedResult<Taxpayer>>(new PaginatedResult());

  const [data, setData] = useState<PaginatedResult<GetTaxpayerViewModel>>(
    new PaginatedResult()
  );
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  const fetchDate = useCallback(async () => {
    var res = await httpService(ENDPIONTS.taxpayers, queryStrings).getAll();
    setData(res.data);
    setIsFetchingPage(false);
  }, [queryStrings]);

  useEffect(() => {
    setIsFetchingPage(true);

    fetchDate();
  }, [fetchDate]);

  const headers: ComplexHeader[] = [
    { key: "id", title: "Id" },
    { key: "tin", title: "TIN No." },
    { key: "taxpayerName", title: "Txpayer Name" },
    { key: "section", title: "section" },
    { key: "currency", title: "currency" },
    { key: "deviceId", title: "Device ID" },
    { key: "targetDB", title: "targetDB" },
    {
      key: "lastReportedDate",
      title: "Last Reported Date",
      format: "date",
      renderer: {
        condition: (taxpayer: GetTaxpayerViewModel) =>
          new Date(taxpayer?.lastReportedDate ?? "").toLocaleDateString() ===
          new Date().toLocaleDateString(),
        ifTrue: "badge badge-success",
        ifFalse: "badge badge-danger",
      },
    },
    { key: "dateDifference", title: "unreported days" },
  ];

  const actions: Action[] = [
    {
      key: "1",
      click: (taxpayer: GetTaxpayerViewModel) => {
        showDocDetails(taxpayer);
      },
      title: "Edit",
      icon: FiEdit,
      color: "success",
    },
    // {
    //     key: '2',
    //     click: (taxpayer: Taxpayer) => {
    //         deleteTaxpayer(taxpayer);
    //     },
    //     title: "Delete",
    //     icon: FiDelete,
    //     color: "danger"
    // },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const showDocDetails = (taxpayer: GetTaxpayerViewModel) => {
    console.log("editing data", taxpayer);
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <AddTaxpayer taxpayer={taxpayer} callback={fetchDate} />,
    });
  };

  const Add = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <AddTaxpayer callback={fetchDate} />,
    });
  };

  const deleteTaxpayer = async (taxpayer: Taxpayer) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this")) {
      var res = await httpService(ENDPIONTS.taxpayers).delete(taxpayer.id);
      if (res?.status === 200) {
        Swal.fire({
          icon: "warning",
          showConfirmButton: false,
          text: "The record has been Deleted successfully.",
        });
        window.location.reload();
      }
    }
  };

  var stylingObject = {
    input: {
      borderRadius: "3px",
      border: "1px solid gray",
    },
    button: {
      border: "1px solid gray",
      fontWeight: "bold",
    },
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    var options = new PagingOptions(TableDefaults.page, TableDefaults.size);

    data?.tinNo && options.filter<Taxpayer>((f) => f.eq("tin", data?.tinNo));
    data?.tradeName &&
      options.filter<Taxpayer>((f) => f.like("taxpayerName", data?.tradeName));
    data?.deviceId &&
      options.filter<Taxpayer>((f) => f.like("deviceId", data?.deviceId));

    navigate({ search: `?${options.format()}` });
  };


  // const onSubmit = async (data: any, e: any) => {
  //   e.preventDefault();
  //   var options = new PagingOptions(TableDefaults.page, TableDefaults.size);


  //   data?.tinNo && options.filter<Taxpayer>(f => f.eq("tin", data?.tinNo))
  //   data?.tradeName && options.filter<Taxpayer>(f => f.like("taxpayerName", data?.tradeName))
  //   data?.deviceId && options.filter<Taxpayer>(f => f.like("deviceId", data?.deviceId))

  //   navigate({ search: `?${options.format()}` });
  // }



  return (
    <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <div className="d-flex col-12 layout-top-spacing align-items-start">

        <form onSubmit={handleSubmit(onSubmit)} className="col-9 offset-1 alert alert-info d-flex flex-row">

          <div className='col-7'>
            <div className='d-flex align-items-center'>
              <p className='w-50'>Tin</p>
              <input type="text" name="tinNo" ref={register()} style={stylingObject.input} />
            </div>
            <div className='d-flex align-items-center'>
              <p className='w-50'>Trade Name</p>
              <input type="text" name="tradeName" ref={register()} style={stylingObject.input} />
            </div>
            <div className='d-flex align-items-center'>
              <p className='w-50'>Device ID</p>
              <input type="text" name="deviceId" ref={register()} style={stylingObject.input} />
            </div>

          </div>
          <div className='col-2'>
            <div>
              <button type="reset" onClick={() => { navigate({ search: `` }); setTotalFilteredInvoices(0) }} className='btn mt-1' style={{ backgroundColor: "#d9534f", color: "white" }}><FiX size={20} /> Reset Filter</button>
              <button type="submit" className='btn mt-1' style={{ backgroundColor: "#5cb85c", color: "white" }}><FiCheck size={20} /> Apply Filter</button>
            </div>
          </div>
        </form>
      </div>
      {/* </div>
        // </form>
      </div > */}
      <div className="skills layout-spacing ">
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-primary "
            onClick={Add}
            style={{ padding: 12, margin: 6, marginLeft: 4 }}
          >
            Add New Taxpayer
          </button>
        </div>
        <div className="widget-content widget-content-area">
          <Table
            data={data}
            headers={headers}
            actions={actions}
            onPageChange={handlePageChange}
            showCounter
            aboveTablePaination
            isFetchingPage={isFetchingPage}
          />
        </div>
      </div>
    </div >
  );
};

export default Taxpayers;
